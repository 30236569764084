import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    maxWidth: 1200,
  },
  media: {
    height: 300,
    width: 300,
  },
});

interface CardProps {
  title: string;
  imgUrl?: string;
  children: React.ReactNode;
  artistUrl?: string;
}

const MediaCard: React.FC<CardProps> = (props: CardProps) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      {props.imgUrl && <CardMedia component="img" image={props.imgUrl} title={props.title} />}
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {props.title}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {props.children}
        </Typography>
      </CardContent>
      {props.artistUrl && (
        <CardActions>
          <Button
            size="small"
            color="primary"
            onClick={() => window.location.assign(props.artistUrl ? props.artistUrl : '')}
          >
            Artist
          </Button>
          <Button size="small" color="primary" onClick={() => alert('lol')}>
            Learn More
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

export default MediaCard;
