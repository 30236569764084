import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Albums, LoginCallback, Welcome, Album, Song } from '../routes';
import Header from '../components';
import AuthRoute from '../components/AuthRoute/AuthRoute';
import './App.css';
import CurrentlyPlaying from '../components/CurrentlyPlaying/CurrentlyPlaying';
import { Profile } from '../routes/Profile/Profile';
import { Dashboard } from '../routes/Dashboard/Dashboard';
import Playlist from '../routes/Playlist/Playlist';

const App: React.FC = () => {
  return (
    <div className="App">
      <Router basename={process.env.REACT_APP_ROUTER_BASE || ''}>
        <Header />
        <AuthRoute component={CurrentlyPlaying} />
        <main>
          <Route exact path="/">
            <Welcome />
          </Route>
          <Route exact path="/dashboard">
            <AuthRoute component={Dashboard} />
          </Route>
          <Route exact path="/login-callback">
            <LoginCallback />
          </Route>
          <Route exact path="/profile">
            <AuthRoute component={Profile} />
          </Route>
          <Route exact path="/playlist/:id">
            <AuthRoute component={Playlist} />
          </Route>
          <Route exact path="/albums">
            <AuthRoute component={Albums} />
          </Route>
          <Route exact path="/album/:id">
            <AuthRoute component={Album} />
          </Route>
          <Route exact path="/song/:id">
            <AuthRoute component={Song} />
          </Route>
        </main>
      </Router>
    </div>
  );
};

export default App;
