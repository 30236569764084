// TODO: use https://www.w3schools.com/js/js_cookies.asp

export const getCookie = (key: string): string | Date | undefined => {
  const cookie = document.cookie;
  const cookies = new Map();
  const segments = cookie.split(';');
  for (const segment of segments) {
    const [key, value] = segment.split('=');
    cookies.set(key.trim(), value);
  }
  return cookies.get(key);
};

export const setCookie = (key: string, value: string, expires: number): void => {
  const date = new Date();
  date.setSeconds(date.getSeconds() + expires);
  document.cookie = `${key}=${value};expires=${date};path=/`;
};
