import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { AuthToken } from '../../util';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid/Grid';
import Container from '@material-ui/core/Container';
import List from '../../components/List/List';
import Card from '../../components/Card/Card';
import prettyMilliseconds from 'pretty-ms';
import { Track } from '../Album/Album.types';
import { TokenProps } from '../../components/AuthRoute/AuthRoute';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    large: {
      width: theme.spacing(14),
      height: theme.spacing(14),
    },
  }),
);

interface TopTrackResponse {
  items: Track[];
  limit: number;
  next: string;
  offset: number;
  previous: string;
  total: number;
}

interface MeResponse {
  display_name: string;
  external_urls: { spotify: string };
  followers: { href: string | null; total: number };
  href: string;
  id: string;
  images: {
    height: number | null;
    url: string;
    width: number | null;
  }[];
  type: string;
  uri: string;
}

export const Profile: React.FC<TokenProps> = (props: TokenProps) => {
  const [profileData, setProfileData] = useState<MeResponse>();
  const [topTracks, setTopTracks] = useState<TopTrackResponse>();
  const [error, setError] = useState<boolean>(false);
  const classes = useStyles();

  const getProfile = async (token?: AuthToken): Promise<MeResponse> => {
    if (token) {
      const url = `https://api.spotify.com/v1/me`;
      const res = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token.value,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      return await res.json();
    }
    return Promise.reject();
  };

  const getTop = async (type: string, token?: AuthToken): Promise<TopTrackResponse> => {
    if (token) {
      const url = `https://api.spotify.com/v1/me/top/${type}`;
      const res = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token.value,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      return await res.json();
    }
    return Promise.reject();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const profile = await getProfile(props.token);
        setProfileData(profile);
        const topT = await getTop('tracks', props.token);
        setTopTracks(topT);
      } catch (e) {
        setError(true);
      }
    };

    fetchData();
  }, []);

  if (!error && profileData && topTracks) {
    return (
      <Container fixed>
        <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={1}>
          <Grid item xs={12}>
            <div className={classes.root}>
              <Avatar alt="Me" src={profileData.images[0].url} className={classes.large} />
              <h1>{profileData.display_name}</h1>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Card title={'Top tracks'}>
              <List
                list={topTracks.items.map((track: Track) => {
                  return {
                    primary: track.name,
                    secondary: prettyMilliseconds(track.duration_ms),
                    link: `/song/${track.id}`,
                    img: track.album.images[0].url,
                  };
                })}
              />
            </Card>
          </Grid>
        </Grid>
      </Container>
    );
  }
  return null;
};
