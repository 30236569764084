import { AuthToken } from '../../util';
import {
  AUTH_SUCCEEDED,
  AUTH_FAILED,
  AUTH_REMOVE,
  AuthFailedAction,
  AuthRemoveAction,
  AuthSucceededAction,
  AuthError,
} from '../types';

export const AuthSucceededA = (token: AuthToken): AuthSucceededAction => {
  return {
    type: AUTH_SUCCEEDED,
    payload: token,
  } as AuthSucceededAction;
};

export const AuthFailedA = (type: AuthError): AuthFailedAction => {
  return {
    type: AUTH_FAILED,
    payload: type,
  } as AuthFailedAction;
};

export const AuthRemoveA = (): AuthRemoveAction => {
  return {
    type: AUTH_REMOVE,
  } as AuthRemoveAction;
};
