import React, { useEffect } from 'react';
import { extractToken, AuthToken, setTokenCookies } from '../../util';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AppState, AUTH_SUCCEEDED, AuthState, AUTH_FAILED } from '../../state/types';

export const LoginCallback: React.FC = () => {
  const authState = useSelector<AppState, AuthState>((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    const token: AuthToken = extractToken();
    if (token) {
      setTokenCookies(token);
      dispatch({
        type: AUTH_SUCCEEDED,
        payload: token,
      });
    } else {
      dispatch({ type: AUTH_FAILED });
    }
  }, [dispatch]);

  if (authState.token) {
    return <Redirect to="/dashboard" />;
  }
  return null;
};
