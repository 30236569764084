import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
      width: 151,
      height: 151,
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    playIcon: {
      height: 38,
      width: 38,
    },
    link: {
      textDecoration: 'none',
    },
  }),
);

interface PlayCardProps {
  name: string;
  artist: string;
  imgUrl: string;
  trackId: string;
  action: () => void;
}

const PlayCard = (props: PlayCardProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component="h5" variant="h5">
            {props.name}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {props.artist}
          </Typography>
        </CardContent>
        <div className={classes.controls}>
          <Link to={`/song/${props.trackId}`} className={classes.link}>
            <Button variant="contained" onClick={props.action}>
              Lyrics
            </Button>
          </Link>
        </div>
      </div>
      <CardMedia className={classes.cover} image={props.imgUrl} title={props.name} />
    </Card>
  );
};

export default PlayCard;
