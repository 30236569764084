export const CONFIG_INITIALIZED = 'CONFIG_INITIALIZED';
export const CONFIG_CHANGED = 'CONFIG_CHANGED';

export interface AppConfig {
  recentlyPlayedOpened: boolean;
}

export interface ConfigInitializedAction {
  type: typeof CONFIG_INITIALIZED;
}

export interface ConfigChangedAction {
  type: typeof CONFIG_CHANGED;
  payload: AppConfig;
}

export interface ConfigState {
  recentlyPlayedOpened: boolean;
}

export const configInit: ConfigState = {
  recentlyPlayedOpened: false,
};

export type ConfigActionTypes = ConfigInitializedAction | ConfigChangedAction;
