import { ConfigInitializedAction, CONFIG_INITIALIZED, ConfigChangedAction, CONFIG_CHANGED } from '../types';

export const ConfigInitializedA = (): ConfigInitializedAction => {
  return {
    type: CONFIG_INITIALIZED,
  } as ConfigInitializedAction;
};

export const ConfigChangedA = (recentlyPlayedOpened: boolean): ConfigChangedAction => {
  return {
    type: CONFIG_CHANGED,
    payload: { recentlyPlayedOpened },
  } as ConfigChangedAction;
};
