import React, { useState, useEffect } from 'react';
import { AuthToken } from '../../util';
import { useLocation } from 'react-router-dom';
import { getId } from '../../util/url.util';
import { Track } from '../Album/Album.types';
import Card from '../../components/Card/Card';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import './Song.css';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { TokenProps } from '../../components/AuthRoute/AuthRoute';
import Button from '@material-ui/core/Button';
import CachedIcon from '@material-ui/icons/Cached';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

interface Lyrics {
  lyrics: string;
  hasNext: boolean;
  title: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: '100%',
      padding: '0',
      marginTop: '-20px',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
    button: {
      margin: theme.spacing(1),
    },
    sad: {
      fontSize: 32,
      display: 'inline',
    },
    notFound: {
      marginTop: '30px',
    },
  }),
);

function LinearIndeterminate() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <LinearProgress color="secondary" />
    </div>
  );
}

const Song: React.FC<TokenProps> = (props) => {
  const [lyrics, setLyrics] = useState<Lyrics | undefined>();
  const [localPage, setPage] = useState(1);
  const [track, setTrack] = useState<Track | undefined>();
  const [error, setError] = useState<boolean>(false);
  const location = useLocation();
  const classes = useStyles();

  const getTrack = async (id: string, token?: AuthToken): Promise<Track> => {
    if (token) {
      const url = `https://api.spotify.com/v1/tracks/${id}`;
      const res = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token.value,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      return await res.json();
    }
    return Promise.reject();
  };

  const getLyrics = async (name: string, artist: string, songId: string, page?: number): Promise<Lyrics> => {
    const query = artist + ' ' + name;
    const res = await fetch(`/api/lyrics?q=${query}&songId=${songId}${page ? '&page=' + page : ''}`);
    if (!res.ok) {
      throw new Error();
    }
    return await res.json();
  };

  const fetchData = async (page?: number) => {
    const id = getId(location.pathname);
    if (id) {
      try {
        const track = await getTrack(id, props.token);
        setTrack(track);
        const lyrics = await getLyrics(track.name, track.artists.map((a) => a.name).join(' '), id, page);
        setLyrics(lyrics);
        if (page) {
          setPage((p) => p + 1);
        }
      } catch (e) {
        setError(true);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (lyrics || error) {
    return (
      <Container fixed>
        <Grid container direction="column" justify="flex-start" alignItems="center">
          <Grid item xs={12}>
            <Card title={'Lyrics - ' + track?.name + ' by ' + track?.artists.map((a) => a.name).join(', ')}>
              {!error && lyrics && (
                <div className="lyrics">
                  <>
                    <CardContent>
                      <Typography variant="body2" component="p">
                        {lyrics.lyrics}
                      </Typography>
                      {lyrics.hasNext && (
                        <Typography className={classes.notFound} color="textSecondary">
                          {lyrics.title} was not what you were looking for? Try to press next.
                        </Typography>
                      )}
                    </CardContent>

                    <CardActions disableSpacing>
                      {lyrics.hasNext && (
                        <Button
                          variant="contained"
                          color="default"
                          className={classes.button}
                          startIcon={<NavigateNextIcon />}
                          onClick={() => fetchData(localPage)}
                        >
                          Next
                        </Button>
                      )}
                    </CardActions>
                  </>
                </div>
              )}
              {error && (
                <div>
                  <div className={classes.sad}>:(</div>
                  <p>Lyrics not found or web scraping failed. Try to reload.</p>
                  <Button
                    variant="contained"
                    color="default"
                    className={classes.button}
                    startIcon={<CachedIcon />}
                    onClick={() => window.location.reload()}
                  >
                    Reload
                  </Button>
                </div>
              )}
            </Card>
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return <LinearIndeterminate />;
  }
};

export default Song;
