import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/App';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './state/reducers';
import logger from 'redux-logger';
import { ConfigInitializedA } from './state/actions/configActions';
// import * as serviceWorker from './serviceWorker';

const middlewares = [];
if (process.env.NODE_ENV !== 'production') {
  middlewares.push(logger);
}
const store = compose(applyMiddleware(...middlewares))(createStore)(rootReducer);

store.dispatch(ConfigInitializedA());

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

// serviceWorker.register();
