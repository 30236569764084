import React, { useEffect, useState } from 'react';
import { AuthToken, Token } from '../../util';
import { CurrentlyPlayingRes } from './CurrentlyPlaying.types';
import SimpleDialog from '../SimpleDialog/SimpleDialog';
import PlayCard from '../PlayCard/PlayCard';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AppState, ConfigState } from '../../state/types';
import { ConfigChangedA } from '../../state/actions/configActions';

interface CurrentlyPlayingProps {
  token: AuthToken;
}

const getCurrent = async (token: Token): Promise<CurrentlyPlayingRes | undefined> => {
  const res = await fetch('https://api.spotify.com/v1/me/player/currently-playing', {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + token.value,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  if (res.status === 204) {
    return undefined;
  } else {
    return await res.json();
  }
};

const CurrentlyPlaying: React.FC<CurrentlyPlayingProps> = (props) => {
  const [playing, setPlaying] = useState<CurrentlyPlayingRes | undefined>();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const isSongPage = location && location.pathname && location.pathname.startsWith('/song');
  const configState: ConfigState = useSelector((state: AppState) => state.config);
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const fetchData = async (token: Token) => {
      try {
        const current = await getCurrent(token);
        if (current) {
          dispatch(ConfigChangedA(true));
        }
        setPlaying(current);
        setOpen(true);
      } catch (e) {
        console.log(e);
      }
    };
    if (props.token && !configState.recentlyPlayedOpened) {
      fetchData(props.token);
    }
  }, []);

  if (playing && !isSongPage) {
    return (
      <SimpleDialog open={open} onClose={handleClose}>
        <PlayCard
          name={playing.item.name}
          artist={playing.item.artists[0].name}
          imgUrl={playing.item.album.images[1].url}
          trackId={playing.item.id}
          action={handleClose}
        />
      </SimpleDialog>
    );
  } else {
    return null;
  }
};

export default CurrentlyPlaying;
