import { ConfigActionTypes, ConfigState, CONFIG_CHANGED, CONFIG_INITIALIZED, configInit } from '../types';

export const configReducer = (state = configInit, action: ConfigActionTypes): ConfigState => {
  switch (action.type) {
    case CONFIG_INITIALIZED:
      return Object.assign({}, state, configInit);
    case CONFIG_CHANGED:
      return Object.assign({}, state, action.payload);
    default:
      return Object.assign({}, state);
  }
};
