import React from 'react';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { Track } from '../../routes/Album/Album.types';
import { Link } from 'react-router-dom';

interface TimelineProps {
  items: {
    context: unknown;
    played_at: string;
    track: Track;
  }[];
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  title: {
    color: '#000',
  },
}));

const TimelineWrapper: React.FC<TimelineProps> = (props: TimelineProps) => {
  const classes = useStyles();

  return (
    <Timeline align="alternate">
      {props.items.map((item, i) => {
        const date = new Date(item.played_at);
        return (
          <TimelineItem key={i}>
            <TimelineOppositeContent>
              <Typography variant="body2" color="secondary">
                {date.toLocaleString()}
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot>
                <Avatar alt={item.track.album.name} src={item.track.album.images[0].url} />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Link to={`/song/${item.track.id}`} style={{ textDecoration: 'inherit', color: 'inherit' }}>
                <Paper elevation={3} className={classes.paper}>
                  <Typography variant="h6" component="h1" className={classes.title}>
                    {item.track.name}
                  </Typography>
                  <Typography>
                    {item.track.artists.map((artist, i) => {
                      return `${artist.name}${i !== item.track.artists.length - 1 ? ', ' : ''}`;
                    })}
                  </Typography>
                </Paper>
              </Link>
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline>
  );
};

export default TimelineWrapper;
