import React from 'react';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Skeleton from '@material-ui/lab/Skeleton';

const TimelineLoading: React.FC<{ n: number }> = (props: { n: number }) => {
  const range = Array.from(Array(props.n).keys());
  return (
    <Timeline align="alternate">
      {range.map((m, i) => {
        return (
          <TimelineItem key={i}>
            <TimelineOppositeContent>
              <Skeleton variant="text" />
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot>
                <Skeleton variant="circle" width={40} height={40} />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Skeleton variant="rect" height={80} />
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline>
  );
};

export default TimelineLoading;
