import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import '../Albums/Albums.css';
import { useLocation } from 'react-router-dom';
import Card from '../../components/Card/Card';
import List from '../../components/List/List';
import Typography from '@material-ui/core/Typography';
import { getId } from '../../util/url.util';
import prettyMilliseconds from 'pretty-ms';
import { TokenProps } from '../../components/AuthRoute/AuthRoute';
import { Album as IAlbum } from './Album.types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: '100%',
      padding: '0',
      marginTop: '-20px',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  }),
);

function LinearIndeterminate() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <LinearProgress color="secondary" />
    </div>
  );
}

const Album: React.FC<TokenProps> = (props: TokenProps) => {
  const [album, setAlbum] = useState<IAlbum | undefined>();
  const location = useLocation();

  useEffect(() => {
    const id = getId(location.pathname);
    if (id && props.token) {
      const url = `https://api.spotify.com/v1/albums/${id}`;
      fetch(url, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + props.token.value,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then((result) => result.json())
        .then((data) => setAlbum(data as IAlbum));
    }
  }, []);

  if (album) {
    return (
      <Container fixed>
        <Grid container direction="column" justify="flex-start" alignItems="center">
          <Grid item xs={12}>
            <Card
              title={album.name}
              imgUrl={album.images.length > 0 ? album.images[0].url : ''}
              artistUrl={album.artists[0].external_urls.spotify}
            >
              <Typography variant="h6" component="span">
                {album.artists.map((artist, i) => (i === album.artists.length - 1 ? artist.name : artist.name + ', '))}
              </Typography>
              <List
                list={album.tracks.items.map((track) => {
                  return {
                    primary: track.name,
                    secondary: prettyMilliseconds(track.duration_ms),
                    link: `/song/${track.id}`,
                  };
                })}
              />
            </Card>
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return <LinearIndeterminate />;
  }
};

export default Album;
