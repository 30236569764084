import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AuthToken, getToken, isTokenValid } from '../../util';
import { AppState, AuthState } from '../../state/types';
import { Redirect } from 'react-router-dom';
import { AuthSucceededA, AuthFailedA } from '../../state/actions/authActions';

export interface TokenProps {
  token: AuthToken;
}

interface AuthRouteProps {
  component: React.FC<TokenProps>;
}

const AuthRoute: React.FC<AuthRouteProps> = (props: AuthRouteProps) => {
  const authState: AuthState = useSelector((state: AppState) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    const token = getToken();
    if (isTokenValid(token)) {
      if (!authState.token) {
        dispatch(AuthSucceededA(token));
      }
    } else {
      dispatch(AuthFailedA('INVALID_OR_EXPIRED'));
    }
  }, [dispatch]);

  const Route = props.component;

  if (authState.token || authState.error) {
    if (authState.error) {
      return <Redirect to="/" />;
    }
    return <Route token={authState.token} />;
  }
  return null;
};

export default AuthRoute;
