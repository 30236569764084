import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LibraryMusicIcon from '@material-ui/icons/LibraryMusic';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: 752,
      textDecoration: 'none',
    },
    demo: {
      backgroundColor: theme.palette.background.paper,
    },
    title: {
      margin: theme.spacing(4, 0, 2),
    },
    link: {
      textDecoration: 'none',
      color: 'inherit',
    },
  }),
);

interface ListProps {
  list: {
    primary: string;
    secondary: string;
    link: string;
    img?: string;
  }[];
}

const InteractiveList: React.FC<ListProps> = (props: ListProps) => {
  const classes = useStyles();
  const { list } = props;

  return (
    <div className={classes.root}>
      <div className={classes.demo}>
        <List dense={true}>
          {list.map((item, i) => (
            <Link to={item.link} className={classes.link} key={i}>
              <ListItem>
                <ListItemIcon>
                  {!item.img ? <LibraryMusicIcon /> : <Avatar variant="square" alt="Remy Sharp" src={item.img} />}
                </ListItemIcon>
                <ListItemText primary={item.primary} secondary={item.secondary} />
              </ListItem>
            </Link>
          ))}
        </List>
      </div>
    </div>
  );
};

export default InteractiveList;
