import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { AuthToken } from './../../util';
import './Albums.css';
import { Link } from 'react-router-dom';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useEffect, useState } from 'react';
import { IAlbums, Item } from './Albums.types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TokenProps } from '../../components/AuthRoute/AuthRoute';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: '100%',
      padding: '0',
      marginTop: '-20px',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  }),
);

function LinearIndeterminate() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <LinearProgress color="secondary" />
    </div>
  );
}

const Albums: React.FC<TokenProps> = (props) => {
  const [albums, setAlbums] = useState<Item[]>([] as Item[]);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);

  const getAlbumsPaged = async (token?: AuthToken): Promise<IAlbums> => {
    if (token) {
      const result = await fetch(`https://api.spotify.com/v1/me/albums?offset=${offset}`, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token.value,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      return await result.json();
    } else {
      return Promise.reject();
    }
  };

  useEffect(() => {
    const getData = async (token?: AuthToken) => {
      if (token) {
        const data = await getAlbumsPaged(token);
        setAlbums(data.items);
        setHasNextPage(data.next !== null);
        setOffset((o) => o + data.items.length);
      }
    };
    getData(props.token);
  }, []);

  const handleLoadMore = () => {
    setLoading(true);
    getAlbumsPaged(props.token).then((newPage) => {
      setLoading(false);
      setHasNextPage(newPage.next !== null);
      setAlbums([...albums, ...newPage.items]);
      setOffset(offset + newPage.items.length);
    });
  };

  const infiniteRef = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: handleLoadMore,
  });

  if (albums) {
    return (
      <Container fixed>
        <span ref={infiniteRef}>
          <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={1}>
            {albums?.map((item, i) => (
              <Grid item xs={6} sm={6} md={3} lg={3} xl={3} key={i}>
                <Link to={`/album/${item.album.id}`} className="cardLinks">
                  <Card>
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        src={item.album.images.length > 0 ? item.album.images[0].url : ''}
                        title="Cover"
                      />
                      <CardContent className="cardContent">
                        <Typography gutterBottom variant="h5" component="h2" className="albumName">
                          {item.album.name}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Link>
              </Grid>
            ))}
            {loading && <CircularProgress color="secondary" />}
            {!loading && <div className="spacer" />}
          </Grid>
        </span>
      </Container>
    );
  } else {
    return <LinearIndeterminate />;
  }
};

export default Albums;
