import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import '../Albums/Albums.css';
import { useParams } from 'react-router-dom';
import Card from '../../components/Card/Card';
import List from '../../components/List/List';
import prettyMilliseconds from 'pretty-ms';
import { TokenProps } from '../../components/AuthRoute/AuthRoute';
import { Item, IPlaylist } from './Playlist.types';
import { Button, CardActions } from '@material-ui/core';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import sanitize from 'sanitize-filename';

const zip = new JSZip();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: '100%',
      padding: '0',
      marginTop: '-20px',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  }),
);

function LinearIndeterminate() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <LinearProgress color="secondary" />
    </div>
  );
}

const Playlist: React.FC<TokenProps> = (props: TokenProps) => {
  const [playlistItems, setPlaylistItems] = useState<Item[] | undefined>();
  const [coverBlobs, setCoverBlobs] = useState<{ blob?: Blob; name: string }[]>([]);
  const { id } = useParams<{ id: string }>();
  const [offset, setOffset] = useState(0);
  const [allItemsLoaded, setAllItemsLoaded] = useState(false);

  const downloadCovers = () => {
    coverBlobs.forEach((item, i) => {
      if (item.blob) {
        zip.file(`${i}-${sanitize(item.name)}.jpg`, item.blob);
      }
    });
    zip.generateAsync({ type: 'blob' }).then(function (content) {
      saveAs(content, `covers-${Date.now()}.zip`);
    });
  };

  useEffect(() => {
    if (id && props.token) {
      const url = `https://api.spotify.com/v1/playlists/${id}/tracks?offset=${offset}`;
      fetch(url, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + props.token.value,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then((result) => result.json())
        .then((data: IPlaylist) => {
          setPlaylistItems((prevItems) => (prevItems ? prevItems.concat(...data.items) : data.items));
          if (data.next) {
            setOffset((prevPage) => prevPage + 100);
          } else {
            setAllItemsLoaded(true);
          }
        });
    }
  }, [offset]);

  useEffect(() => {
    const doDownload = async () => {
      if (playlistItems) {
        const blobs = await Promise.all(
          playlistItems.map(async (item) => {
            if (item.track.album.images.length) {
              const image = await fetch(item.track.album.images[0].url);
              return { blob: await image.blob(), name: item.track.name };
            }
            return { blob: undefined, name: item.track.name };
          }),
        );
        setCoverBlobs(blobs);
      }
    };
    if (allItemsLoaded) {
      doDownload();
    }
  }, [playlistItems, allItemsLoaded]);

  if (playlistItems) {
    return (
      <Container fixed>
        <Grid container direction="column" justify="flex-start" alignItems="center">
          <Grid item xs={12}>
            <Card title={'Some playlist'}>
              <List
                list={playlistItems.map((item) => {
                  return {
                    primary: item.track.name,
                    secondary: prettyMilliseconds(item.track.duration_ms),
                    link: `/song/${item.track.id}`,
                    img: item.track.album.images.length ? item.track.album.images[0].url : undefined,
                  };
                })}
              />
              {allItemsLoaded && coverBlobs.length && (
                <CardActions>
                  <Button size="small" color="primary" onClick={downloadCovers}>
                    Download covers
                  </Button>
                </CardActions>
              )}
            </Card>
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return <LinearIndeterminate />;
  }
};

export default Playlist;
