import { Container, Grid } from '@material-ui/core';
import React from 'react';
import { useApi } from '../../hooks/useApi';
import Timeline from '../../components/Timeline/Timeline';
import TimelineLoading from '../../components/Timeline/TimelineLoading';
import { Track } from '../Album/Album.types';
import { makeStyles } from '@material-ui/core/styles';
import List from '../../components/List/List';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { TokenProps } from '../../components/AuthRoute/AuthRoute';

interface RecentlyPlayedResponse {
  href: string;
  items: {
    context: unknown;
    played_at: string;
    track: Track;
  }[];
  limit: number;
  next: string;
}

interface PlaylistResponse {
  href: string;
  items: {
    collaborative: boolean;
    description: string;
    external_urls: {
      spotify: string;
    };
    href: string;
    id: string;
    images: {
      height: number;
      url: string;
      width: number;
    }[];
    name: string;
    owner: {
      display_name: string;
    };
    tracks: {
      href: string;
      total: number;
    };
    type: string;
  }[];
  limit: number;
  next: string;
  offset: number;
  previous: string;
  total: number;
}

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export const Dashboard: React.FC<TokenProps> = (props: TokenProps) => {
  const limit = 5;
  const pl = useApi<PlaylistResponse>({
    token: props.token,
    url: 'https://api.spotify.com/v1/me/playlists',
  });
  const rp = useApi<RecentlyPlayedResponse>({
    token: props.token,
    url: `https://api.spotify.com/v1/me/player/recently-played?limit=${limit}`,
  });

  const classes = useStyles();

  return (
    <Container fixed>
      <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={4}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Card className={classes.root}>
            <CardContent>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                Recently played
              </Typography>
              {rp.data?.items && <Timeline items={rp.data.items} />}
              {rp.loading && <TimelineLoading n={limit} />}
              {rp.error && <h1>Something went wrong</h1>}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Card className={classes.root}>
            <CardContent>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                Playlists
              </Typography>
              {pl.data?.items && (
                <List
                  list={pl.data.items.map((pl) => {
                    return {
                      primary: pl.name,
                      secondary: pl.tracks.total.toString(),
                      link: `/playlist/${pl.id}`,
                      img: pl.images.length ? pl.images[0].url : undefined,
                    };
                  })}
                />
              )}
              {pl.error && <h1>Something went wrong</h1>}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};
