import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { useSelector, useDispatch } from 'react-redux';
import { AppState, AuthState } from '../../state/types';
import { Link, useHistory } from 'react-router-dom';
import './Header.css';
import { AuthRemoveA } from '../../state/actions/authActions';
import { removeTokenCookies } from '../../util';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      color: '#fff',
    },
    title: {
      flexGrow: 1,
    },
    bar: {
      backgroundColor: '#1DB954',
    },
    menu: {
      textDecoration: 'none',
      color: '#fff',
    },
  }),
);

export default function MenuAppBar(): JSX.Element {
  const classes = useStyles();
  const [profileMenuEl, setProfileMenuEl] = React.useState<null | HTMLElement>(null);
  const [menuEl, setMenuEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(profileMenuEl);
  const authState = useSelector<AppState, AuthState>((state) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleProfileMenu = (event: React.MouseEvent<HTMLElement>) => {
    setProfileMenuEl(event.currentTarget);
  };

  const handleProfileClose = () => {
    setProfileMenuEl(null);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMenuEl(event.currentTarget);
  };

  const handleClose = () => {
    setMenuEl(null);
  };

  const logout = () => {
    removeTokenCookies();
    dispatch(AuthRemoveA());
    handleProfileClose();
    history.push('/');
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.bar}>
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} aria-label="menu" onClick={handleMenu}>
            <MenuIcon />
          </IconButton>
          <Menu id="simple-menu" anchorEl={menuEl} keepMounted open={Boolean(menuEl)} onClose={handleClose}>
            <Link to="/dashboard" className="noDecoration">
              <MenuItem onClick={handleClose}>Dashboard</MenuItem>
            </Link>
            <Link to="/albums" className="noDecoration">
              <MenuItem onClick={handleClose}>Albums</MenuItem>
            </Link>
          </Menu>
          <Typography variant="h6" className={classes.title}>
            <Link to="/dashboard" className="noDecoration">
              Spotify Tracker
            </Link>
          </Typography>
          {authState.token && (
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleProfileMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={profileMenuEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleProfileClose}
              >
                <Link to="/profile" className="noDecoration">
                  <MenuItem onClick={handleProfileClose}>Profile</MenuItem>
                </Link>
                <MenuItem onClick={logout}>Logout</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}
