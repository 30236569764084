import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  children: JSX.Element;
}

const SimpleDialog = (props: SimpleDialogProps): JSX.Element => {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Currently playing</DialogTitle>
      {props.children}
    </Dialog>
  );
};

export default SimpleDialog;
