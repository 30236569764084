import { getCookie, setCookie } from '.';

const TOKEN = 'access_token';
const EXPIRES = 'expires_in';

export interface Token {
  value: string;
  expires: string;
}

export type AuthToken = Token | false;

const isToken = (token: Token | false): token is Token => {
  return (token as Token).value !== undefined && (token as Token).expires !== undefined;
};

export const isTokenValid = (token: AuthToken): boolean => {
  return isToken(token) && new Date().getTime() < new Date(token.expires).getTime();
};

export const setTokenCookies = (token: Token): void => {
  setCookie('token', token.value, 100);
  const expires = new Date();
  expires.setTime(expires.getTime() + parseInt(token.expires) * 1000);
  setCookie('token_exp', expires.toString(), 9999);
};

export const removeTokenCookies = (): void => {
  document.cookie = 'token=; Max-Age=0';
};

// Extract token from cookies
export const getToken = (): AuthToken => {
  const expiresStr = getCookie('token_exp');
  const tokenValue = getCookie('token');
  if (expiresStr && tokenValue) {
    return {
      value: tokenValue.toString(),
      expires: new Date(expiresStr).toString(),
    };
  }
  return false;
};

// from Spotify callback url
export const extractToken = (): AuthToken => {
  const fullHash = window.location.hash;
  const segments = fullHash.substr(1, fullHash.length).split('&');
  const lookup = new Map<string, string>();
  for (const segment of segments) {
    const [key, value] = segment.split('=');
    lookup.set(key, value);
  }
  const tokenValue = lookup.get(TOKEN);
  const tokenExpires = lookup.get(EXPIRES);
  if (tokenValue && tokenExpires) {
    return {
      key: 'token',
      value: tokenValue,
      expires: tokenExpires,
    } as AuthToken;
  }
  return false;
};
