import { useEffect, useState } from 'react';
import { AuthToken, removeTokenCookies } from './../util';
import { useDispatch } from 'react-redux';
import { AuthFailedA } from '../state/actions/authActions';

interface ApiProps {
  token?: AuthToken;
  url: string;
}

type ApiResponse<T> = {
  loading: boolean;
  data?: T;
  error?: Error;
};

export const useApi = <T>(props: ApiProps): ApiResponse<T> => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<T | undefined>();
  const [error, setError] = useState<Error | undefined>();
  const dispatch = useDispatch();
  const { url, token } = props;

  const getData = async () => {
    if (token) {
      const result = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token.value,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      if (result.status === 401) {
        removeTokenCookies();
        dispatch(AuthFailedA('INVALID_TOKEN'));
      }
      return await result.json();
    }
  };

  useEffect(() => {
    const fetchIt = async () => {
      try {
        setLoading(true);
        const data = await getData();
        setData(data);
        setLoading(false);
      } catch (e) {
        setError(e instanceof Error ? e : new Error('Something went wrong'));
        setLoading(false);
      }
    };
    fetchIt();
  }, []);
  return { loading, data, error };
};
