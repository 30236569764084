import { AuthToken } from '../../util';
export const AUTH_SUCCEEDED = 'AUTH_SUCCEEDED';
export const AUTH_FAILED = 'AUTH_FAILED';
export const AUTH_REMOVE = 'AUTH_REMOVE';

export type AuthError = 'INVALID_OR_EXPIRED' | 'INVALID_TOKEN';

export interface AuthSucceededAction {
  type: typeof AUTH_SUCCEEDED;
  payload: AuthToken;
}

export interface AuthFailedAction {
  type: typeof AUTH_FAILED;
  payload: AuthError;
}

export interface AuthRemoveAction {
  type: typeof AUTH_REMOVE;
}

export interface AuthState {
  token: AuthToken;
  error?: AuthError;
}

export type AuthActionTypes = AuthSucceededAction | AuthFailedAction | AuthRemoveAction;
