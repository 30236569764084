import { AuthActionTypes, AUTH_SUCCEEDED, AUTH_FAILED, AUTH_REMOVE, AuthState } from '../types';

const authInit: AuthState = {
  token: false,
};

export const authReducer = (state: AuthState = authInit, action: AuthActionTypes): AuthState => {
  switch (action.type) {
    case AUTH_SUCCEEDED:
      return Object.assign({}, state, {
        token: action.payload,
        error: undefined,
      });
    case AUTH_FAILED:
      return Object.assign({}, state, {
        token: false,
        error: action.payload,
      });
    case AUTH_REMOVE:
      return Object.assign({}, authInit);
    default:
      return Object.assign({}, state);
  }
};
